/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $.event.add(window, "load", this.resizeFrame);
        $.event.add(window, "resize", this.resizeFrame);

        //== Hamburger
        var $navtoggler = $("#nav-icon, #close-nav"), 
        $body = $("body");

        $navtoggler.on("click", function(e) {
          $body.toggleClass("open-menu");
        });

        //== ScrollToTop
        $('.scrollToTop[href^="#"] ').bind('click.smoothscroll', function(e) {
            e.preventDefault();
            $('html, body').stop().animate({'scrollTop': 0}, 1200, 'easeInOutExpo');
        });

        //== Modal
        $("#close-modal").on("click", function(e) {
            $(this).closest(".modal").modal('hide');
        });

        //== First Pageview -> Show "loader"
        var firstWebsiteView = Cookies.get('firstWebsiteView');

        if ( !firstWebsiteView ) {
          $(".cover").removeClass("d-none");
          $("body").addClass('no-scroll');

          setTimeout( function(){ 
              $(".cover").fadeOut("slow");
               $("body").removeClass('no-scroll');
            }, 3000);

          Cookies.set('firstWebsiteView', 1, { expires: 7, path: '' });
        }
        //Cookies.remove('firstWebsiteView', { path: '' });
        
        //== Toggle imprint
        $(".imprint-toggle").click( function(e) {
          e.preventDefault();

          if ( $('#imprint').is(':hidden') ){
              $('#imprint').stop().slideDown("fast", function() {$(this).css('display', 'block');});
              $('html, body').stop().animate({'scrollTop': $(document).height()}, 1200, 'easeInOutExpo');
          } else{
              $('#imprint').slideUp("fast");
          }

        });

      },
      resizeFrame: function() {
        viewportHeight = $(window).height();
        viewportWidth = $(window).width();

        if (viewportWidth >= 767) {

           //== WOW.js
          new WOW({offset: 0}).init();

          $(".agenda-weekday a").removeAttr('data-toggle');
        } else {
          $(".agenda-weekday a").attr('data-toggle', 'collapse');
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'home': {
      init: function() {
        
      },
      finalize: function() {

      }
    },
    // Termine Page
    'termine': {
      init: function() {
        $.event.add(window, "load", this.resizeFrame);
        $.event.add(window, "resize", this.resizeFrame);
      },
      resizeFrame: function() {

        viewportHeight = $(window).height();
        viewportWidth = $(window).width(); 

        var firstPageviewAgenda = Cookies.get('firstPageviewAgenda');

        if (viewportWidth >= 767 && viewportWidth < 1700) {
          if ( ! firstPageviewAgenda ) {

            setTimeout( function(){ 
              $('#firstPageview').modal('show'); 
              setTimeout( function(){ $('#firstPageview').modal('hide'); }, 3000);
            }, 2000);
            
            Cookies.set('firstPageviewAgenda', 1, { expires: 7, path: '' });
          }
          //Cookies.remove('firstPageviewAgenda', { path: '' });
        }

      },
      finalize: function() {
        
      }
    },
    'kontakt': {
      init: function() {

      },
      finalize: function() {

        /*
        *  add_marker
        */
        function add_marker( $marker, map ) {

          // var
          var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

          // create marker
          var marker = new google.maps.Marker({
            position  : latlng,
            map : map,
            icon : {
              anchor: new google.maps.Point(66, 66),
              size: new google.maps.Size(134,132),
              url: directory_uri.stylesheet_directory_uri + '/dist/images/logo-small.svg'
            }
          });

          // add to array
          map.markers.push( marker );

          // if marker contains HTML, add it to an infoWindow
          if( $marker.html() ) {
            // create info window
            var infowindow = new google.maps.InfoWindow({
              content   : $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {

              infowindow.open( map, marker );

            });
          }

        }

        /*
        *  center_map
        */
        function center_map( map ) {

          // vars
          var bounds = new google.maps.LatLngBounds();

          // loop through all markers and create bounds
          $.each( map.markers, function( i, marker ){
            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
            bounds.extend( latlng );
          });

          // only 1 marker?
          if( map.markers.length === 1 ) {
            // set center of map
              map.setCenter( bounds.getCenter() );
              map.setZoom( 18 );
          } else {
            // fit to bounds
            map.fitBounds( bounds );
          }

        }

        /*
        *  new_map
        */
        function new_map( $el ) {
          
          // var
          var $markers = $el.find('.marker');
          
          // vars
          var args = {
            center  : new google.maps.LatLng(0, 0),
            mapTypeId : google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            styles : [
                {
                    "featureType": "all",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": "0"
                        },
                        {
                            "lightness": "10"
                        }
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        },
                        {
                            "hue": "#ff0000"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        },
                        {
                            "saturation": "0"
                        },
                        {
                            "lightness": "0"
                        }
                    ]
                }
            ]
          };
          
          // create map           
          var map = new google.maps.Map( $el[0], args);
          
          // add a markers reference
          map.markers = [];
          
          // add markers
          $markers.each(function(){
            
              add_marker( $(this), map );
            
          });
          
          // center map
          center_map( map );
          
          // return
          return map;
          
        }

        var map = null;

        $('.acf-map').each(function(){
          map = new_map( $(this) );
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
